import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "shop_address"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_address_list = _resolveComponent("van-address-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: "收货地址",
    "left-text": "返回",
    "left-arrow": "",
    onClickLeft: $setup.onClickLeft
  }, null, 8, ["onClickLeft"]), _createVNode(_component_van_address_list, {
    modelValue: $setup.chosenAddressId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.chosenAddressId = $event),
    list: $setup.list,
    "disabled-list": $setup.disabledList,
    "disabled-text": "以下地址超出配送范围",
    "default-tag-text": "默认",
    "add-button-text": "新增地址",
    onAdd: $setup.onAdd,
    onEdit: $setup.onEdit
  }, null, 8, ["modelValue", "list", "disabled-list", "onAdd", "onEdit"])]);
}