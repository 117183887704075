import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import { NavBar, AddressList, Toast } from 'vant';
// 手动引入 Toast 组件的样式
import 'vant/es/toast/style';
export default {
  setup() {
    // 导航条-返回
    const onClickLeft = () => history.back();
    // 收货地址列表 - 选择第一个
    const chosenAddressId = ref('1');
    // 收货地址列表 - 点击新增地址
    const onAdd = () => {
      console.log("测试输出");
      this.$router.push('shop-address-add');
    };
    // 收货地址列表 - 点击编辑地址
    const onEdit = (item, index) => Toast('编辑地址:' + index);
    // 收货地址列表 - 列表数据
    const list = [{
      id: '1',
      name: '张三',
      tel: '13000000000',
      address: '浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室',
      isDefault: true
    }, {
      id: '2',
      name: '李四',
      tel: '1310000000',
      address: '浙江省杭州市拱墅区莫干山路 50 号'
    }];
    const disabledList = [{
      id: '3',
      name: '王五',
      tel: '1320000000',
      address: '浙江省杭州市滨江区江南大道 15 号'
    }];
    return {
      onClickLeft,
      list,
      onAdd,
      onEdit,
      disabledList,
      chosenAddressId
    };
  }
};